import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import icon1 from "../images/feature_icon1.svg"
import icon2 from "../images/feature_icon2.svg"
import icon3 from "../images/feature_icon3.svg"
import icon4 from "../images/feature_icon4.svg"
import icon5 from "../images/feature_icon5.svg"
import more from "../images/arrow_right.svg"

import openGpaphImage from "../images/opengraph/main.jpg"


function Features () {
  return (
    <Layout>

    <section className="features">
      <div className="container">
        <div className="row">

            <div className="col-xl-6 offset-xl-3 col-lg-12 col-md-12 col-12 text-center">
                <h1>Take authorization in your stride with iamcore</h1>
                <span className="intro_p">Focus on your business value – with a turnkey solution for granular access control with state-of-the-art security.</span>
            </div>


            <div className="col-12 feature-first">
                <div className="row feature-item-wrapper d-flex">

                    <div className="col-sm-8 offset-sm-4 h3-desktop-only">
                        <h4>Seamless granular access control</h4>
                    </div>

                    <div className="row d-flex">
                        <div className="col-md-4 col-12 align-self-center">
                            <img src={icon1} class="img-fluid mx-auto d-block" alt='Seamless granular access control' />
                        </div>
                        <div className="col-md-8 col-12">
                            <h4 class="h3-mobile-only">Seamless granular access control</h4>
                            <ol class="li-counter">
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                <div>Keep sensitive assets safe with centralized identity and access management.</div>
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                <div>
                                Handle even the most sophisticated permission management scenarios with iamcore’s flexible and granular access policy evaluation engine.
                                </div>
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                <div>
                                Model complex organization designs with iamcore multi-tenancy and cross-tenant resource sharing.
                                </div>
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                <div>
                                Plug your external IDPs (Okta, Auth0, KeyCloak, Azure Active Directory, etc.) to connect authentication smoothly.
                                </div>
                                </div>
                            </li>
                            </ol>
                        </div>
                    </div>

                    <div className="col-md-8 offset-md-4 col-12 more-link-wrapper">
                        <a href="/" className="more_link">Learn more<img src={more} alt='more_icon' /></a>
                        <hr />
                    </div>

                </div>
            </div>
            

            <div className="col-12">
                <div className="row feature-item-wrapper d-flex">

                    <div className="col-sm-8 offset-sm-4 h3-desktop-only">
                        <h4>Authorization decoupled from app data</h4>
                    </div>

                    <div className="row d-flex">
                        <div className="col-md-4 col-12 align-self-center">
                            <img src={icon2} class="img-fluid mx-auto d-block" alt='Authorization decoupled from app data' />
                        </div>
                        <div className="col-md-8 col-12">
                            <h4 class="h3-mobile-only">Authorization decoupled from app data</h4>
                            <ol class="li-counter">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>Keep security in check with quick and simple permission management.</div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Take advantage of just how easy and flexible authorization may be. Iterate faster by focusing on what matters most – your business value and customers.
                                    </div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Speed up your time-to-market with lightning fast updates enabled by the future-proof authorization model.
                                    </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="col-md-8 offset-md-4 col-12 more-link-wrapper">
                        <a href="/" className="more_link">Learn more<img src={more} alt='more_icon' /></a>
                        <hr />
                    </div>

                </div>
            </div>


            <div className="col-12">
                <div className="row feature-item-wrapper d-flex">

                    <div className="col-sm-8 offset-sm-4 h3-desktop-only">
                        <h4>Scalability by your rules</h4>
                    </div>

                    <div className="row d-flex">
                        <div className="col-md-4 col-12 align-self-center">
                            <img src={icon3} class="img-fluid mx-auto d-block" alt='Authorization decoupled from app data' />
                        </div>
                        <div className="col-md-8 col-12">
                            <h4 class="h3-mobile-only">Scalability by your rules</h4>
                            <ol class="li-counter">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>Scale with confidence and meet unique user demands with features your users will like by adjusting complexity and granularity of your permission scenarios.</div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Take authorization in your stride – with responsiveness, flexibility, and cost-control provided by iamcore.
                                    </div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Handle advanced scoping of user permissions to keep up with the growing complexity of your SaaS product.
                                    </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="col-md-8 offset-md-4 col-12 more-link-wrapper">
                        <a href="/" className="more_link">Learn more<img src={more} alt='more_icon' /></a>
                        <hr />
                    </div>

                </div>
            </div>



            <div className="col-12">
                <div className="row feature-item-wrapper d-flex">

                    <div className="col-sm-8 offset-sm-4 h3-desktop-only">
                        <h4>Flexible policy format</h4>
                    </div>

                    <div className="row d-flex">
                        <div className="col-md-4 col-12 align-self-center">
                            <img src={icon4} class="img-fluid mx-auto d-block" alt='Flexible policy format' />
                        </div>
                        <div className="col-md-8 col-12">
                            <h4 class="h3-mobile-only">Flexible policy format</h4>
                            <ol class="li-counter">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>Test, optimize, and debug policies before they go live.</div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Define custom relations between users and objects and apply wildcards to designate multiple principals, resources, or actions.
                                    </div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Don't miss a beat with iamcore guaranteed performance: 99% of authorization requests served in under 50ms.
                                    </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="col-md-8 offset-md-4 col-12 more-link-wrapper">
                        <a href="/" className="more_link">Learn more<img src={more} alt='more_icon' /></a>
                        <hr />
                    </div>

                </div>
            </div>


            <div className="col-12">
                <div className="row feature-item-wrapper d-flex">

                    <div className="col-sm-8 offset-sm-4 h3-desktop-only">
                        <h4>Developer-friendly support</h4>
                    </div>

                    <div className="row d-flex">
                        <div className="col-md-4 col-12 align-self-center">
                            <img src={icon5} class="img-fluid mx-auto d-block" alt='Developer-friendly support' />
                        </div>
                        <div className="col-md-8 col-12">
                            <h4 class="h3-mobile-only">Developer-friendly support</h4>
                            <ol class="li-counter">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>Iamcore offers open API and open source SDKs for Java and Go (Python and Node.js are coming soon).</div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Extensive documentation will guide you through the process of using iamcore.
                                    </div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                    <div>
                                    Set up your authorization quickly and easily with a built-in wizard.
                                    </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="col-md-8 offset-md-4 col-12 more-link-wrapper">
                        <a href="/" className="more_link">Learn more<img src={more} alt='more_icon' /></a>
                    </div>

                </div>
            </div>


        </div>
      </div>
    </section>

    <section className="special-section">
      <div className="container">
        <div className="container-special-2">
            <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-12 text-lg-start text-center features-banner">
                        <h3>Have any questions about iamcore?</h3>
                        <span>Meet our expert to discuss your access control needs.</span>
                    </div>
                    <div className="col-lg-4 col-12 special-btn-wrapper text-lg-end text-center">
                        <Link to='/contact-us' className="btn btn-transparent">Book a meeting</Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </section>

    {/* <section className="special-section">
      <div className="container">
        <div className="features-banner-container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12 col-12 text-center">
                    <h3>Secure your SaaS growth and scalability with a fast-start authorization service from iamcore</h3>
                    <Link to='/contact-us' className="btn btn-dark-to-yellow">START NOW</Link>
                </div>
            </div>
        </div>
      </div>
    </section> */}

  </Layout>

  )
}

export const Head = () => <Seo title="Features" description="Set up the user and permission management in minutes." image={openGpaphImage}/>

export default Features
